// Here you can add other styles
.c-sidebar {
  color: #fff;
  background: #282727;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: #ED1B24;
}